import { mapHelper } from "@/utils/common.js";
const oStatus = [
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
  {
    value: 0,
    label: "审核中",
  },
];
const { map: oStatusMap, setOps: oStatusOps } = mapHelper.setMap(oStatus);

export { oStatusMap, oStatus };
