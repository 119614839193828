<template>
  <div class="bestBeautiful">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input
          placeholder="请输入"
          label="家长姓名"
          v-model="searchParam.parentName"
          :width="250"
        ></v-input>
        <v-input
          placeholder="请输入"
          label="联系电话"
          v-model="searchParam.parentMobile"
          :width="250"
        ></v-input>
        <v-date-picker
          label="日期"
          v-model="createoTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
        <v-select
          clearable
          :options="oStatus"
          v-model="searchParam.auditStatus"
          label="状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          :text="scope.row.noEncrypt ? '加密' : '解密'"
          type="text"
          @click="setEncrypt(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { queryAuditListUrl, doAuditUrl } from "./api.js";
import { oStatusMap, oStatus } from "./map.js";

export default {
  name: "bestBeautiful",
  data() {
    return {
      oStatus,
      searchParam: {
        applyStartTime: "",
        applyEndTime: "",
        auditStatus: "",
        parentName: "",
        parentMobile: "",
      },
      tableUrl: queryAuditListUrl,
      headers: [
        {
          prop: "parentName",
          label: "家长姓名",
          formatter: (row, prop) => {
            if (row.noEncrypt) {
              return row[prop];
            }
            return `${
              row[prop] ? `${row[prop].replace(/^(.).+$/, "$1*")}` : ""
            }`;
          },
        },
        {
          prop: "parentMobile",
          label: "家长联系电话",
          formatter: (row, prop) => {
            console.log(row[prop].splice);
            if (row.noEncrypt) {
              return row[prop];
            }
            return `${
              row[prop]
                ? `${row[prop].replace(/^(.{3}).+(.{4})$/, "$1****$2")}`
                : ""
            }`;
          },
        },
        {
          prop: "childrenName",
          label: "子女姓名",
          formatter: (row, prop) => {
            if (row.noEncrypt) {
              return row[prop];
            }
            return `${
              row[prop] ? `${row[prop].replace(/^(.).+$/, "$1*")}*` : ""
            }`;
          },
        },
        {
          prop: "childrenGrade",
          label: "所在班级",
          //   formatter: (row, prop) => {
          //     return timeDuringMap[row[prop]];
          //   },
        },
        {
          prop: "childrenCardNum",
          label: "学号/身份证号",
          formatter: (row, prop) => {
            if (row.noEncrypt) {
              return row[prop];
            }
            return `${
              row[prop]
                ? `${row[prop].replace(/^(.{1}).+(.{1})$/, "$1****$2")}`
                : ""
            }`;
          },
        },
        {
          prop: "createTime",
          label: "日期",
        },

        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            return oStatusMap[row[prop]];
          },
        },
        // {
        //   prop: "applicantsNum",
        //   label: "活动参与人数",
        //   align: "center",
        //     formatter: (row, prop) =>
        //       createAlinkVNode(this, row, prop, {
        //         text: row[prop],
        //         cb: () => this.toUserList(row),
        //       }),
        // },
      ],
    };
  },
  computed: {
    createoTime: {
      get() {
        return !this.searchParam.applyStartTime &&
          !this.searchParam.applyEndTime
          ? []
          : [this.searchParam.applyStartTime, this.searchParam.applyEndTime];
      },
      set(val) {
        [this.searchParam.applyStartTime, this.searchParam.applyEndTime] =
          val || ["", ""];
      },
    },
  },
  methods: {
    toEdit(row) {
      this.$confirm(`是否审核通过?`, "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "通过",
        cancelButtonText: "驳回",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
            auditStatus: "1",
            auditRemarks: "",
          };
          this.$axios.post(doAuditUrl, params).then((res) => {
            if (res.code === 200) {
              this.$message.success("审核成功");
              this.$refs.list.search();
            }
          });
        })
        .catch((action) => {
          // 区分驳回与关闭
          if (action !== "cancel") {
            return;
          }
          let params = {
            id: row.id,
            auditStatus: 2,
            auditRemarks: "",
          };
          this.$axios.post(doAuditUrl, params).then((res) => {
            if (res.code === 200) {
              this.$message.success("驳回成功");
              this.$refs.list.search();
            }
          });
        });
    },
    setEncrypt(row) {
      this.$set(row, "noEncrypt", !row.noEncrypt);
    },
  },
};
</script>
<style lang="less" scoped>
.bestBeautiful {
  box-sizing: border-box;
  height: 100%;
}
</style>
